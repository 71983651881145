:root{
    --font-family-about:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    --animation-translate-from: translateX(0);
    --animation-translate-to: translateX(-100%);
    --animation-origin:move;
    --marque-skeletone-bg:linear-gradient(90deg, rgb(235, 235, 235) 25%, silver 37%, lightgray 63%);
}

*{
    margin: 0;
    padding: 0;
}
.marque-area{
    background-color: #fff;
    width: 1290px;
    height: 30px;
    margin-top: 20px;
    margin-left: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    line-height: 0.6;
    position: relative;
}
.marque-area-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);
}
.marque-area-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: 1;
    z-index: 2;
    min-height: 30px;
    margin-left: -20px;
    width: 1290px;
    border-radius: 30px;
    background: var(--marque-skeletone-bg);
    background-size: 400% 100%;
    animation-name: css-skeleton-marque;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;

}
@keyframes css-skeleton-marque{
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
}
#movingText{
    width: 1200px;
    height: 30px;
    margin-left: 30px;
    padding-left: 30px;
    border-radius: 30px;
    line-height: 0.5;
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    background-color: #fff;
    overflow-x: auto;
}
#movingText::-webkit-scrollbar{
    display: none;
}
#movingText span{
    display: flex;
    font-family: var(--font-family-about);
    font-size: 1.2vw;
    font-weight: 500;
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 40px;
    color: #444;
    gap: 2px;
    background-color: #fff;
}
.scrollText{
    line-height: 0.8;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    animation-name: var(--animation-origin);
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
@keyframes move{
    from{
        transform: var(--animation-translate-from);
    }
    to{
        transform: var(--animation-translate-to);
    }
}
#marbel{
    position: absolute;
    margin-left: 50px;
    width: 15px;
    height: 15px;
    line-height: 1;
    border-radius: 50%;
    background-color: orangered;
}
