:root{
    --font-family-about:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';

}
section.user--login--part{
    background-color: #f2f3f8;
}
.common--card {
    -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    background-color: #fff;
    margin-bottom: 20px;
    border-color: #ebedf2;
}
.text-center{
    font-family: var(--font-family-about);
    font-size: 1.5vw;
    font-weight: 700;
}
/* .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
.login--acc{
    width: 600px;
    height: 100%;
    padding: 10px 20px;
    margin-top: 100px;
}
.text--input{
    display: flex;
    text-align: left;
    align-items: center;
    width: 480px;
    height: 100%;
    margin-left: 35px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #e2e5ec;
}
#email,#password,#confirm_password{
    outline: none;
    box-shadow: none;
}
.login--btn{
    display: flex;
    font-size: 16px;
    font-weight: 700;
    width: 480px;
    height: 100%;
    margin-left: 35px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    border: 1px solid #e2e5ec;
}
.login--btn:hover{
    background-color: antiquewhite;
    --tw-bg-opacity: 1;
    color: rgb(99 102 241 / var(--tw-bg-opacity));
    transition: all 0.06 ease-in-out;
    transform: translateZ(20px);
}
#loginSubmt{
    outline: none;
    box-shadow: none;
    
}
.btn--label{
    text-align: center;
    align-items: center;
    width: 480px;
    color: white;
    letter-spacing: 1px;
}
.btn--label:hover{
    --tw-bg-opacity: 1;
    color: rgb(99 102 241 / var(--tw-bg-opacity));
    transition: all 0.06s ease-in-out;
    transform: translateZ(20px);
}
.forgot--password{
    font-family: var(--font-family-about);
    font-size: 1.1vw;
    font-weight: 400;
    opacity: 1;

}
.forgot--btn{
    position: relative;
    transition: all 0.06s ease-in-out;
    transform: translateZ(20px);
    text-decoration: none;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
    color: #495057;
    font-size: 0.975rem;
    font-weight: 300;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#check{
    border: 1px solid #e2e5ec;
    background-color: white;
}

.checking{
    display: inline-block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 0.875rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.checking--label{
    display: inline-block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #495057;
    font-size: 0.975rem;
    font-weight: 400;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.text--label{
    font-family: var(--font-family-about);
    font-size: 1vw;
    font-weight: 400;
    color: #495057;
    align-items: center;
    text-align: center;
}
.register--label{
    text-decoration: none;
    font-family: var(--font-family-about);
    font-size: 1vw;
    font-weight: 400;
    --tw-bg-opacity: 1;
    color: rgb(99 102 241 / var(--tw-bg-opacity));
    transition: all 0.06s ease-in-out;
    transform: translateZ(20px);
    
}
.regist--text{
    text-align: center;
    align-items: center;
    margin-top: -18px;
    margin-bottom: 20px;
}
.register--acc{
    width: 600px;
    height: 100%;
    padding: 10px 20px;
    margin-top: 120px;
}
.confirm--text--input{
    display: flex;
    text-align: left;
    align-items: center;
    width: 480px;
    height: 100%;
    margin-left: 35px;
    margin-right: 20px;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #e2e5ec;
}

