:root{
    --font-family-features:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    /* --card-shadow:5px 5px 20px rgb(0,0,0,0.4); */
    /* --card-shadow: -10px 10px 26px #c8c8c887; */
    --card-shadow: -3px 3px 10px 3px #c8c8c887;
    /* --card-shadow: -2px 3px 5px rgb(0 0 0 / 0.1); */
    --card-transform:translateY(-1%);
    --dot-skeletone-bg:linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
    --animation: css-skeleton-loading;
    --animation-duration:1.4s;
    --animation-background-size:400% 100%;
    --animation-background-position-0:100% 50%;
    --animation-background-position-100:0 50%;

    --image-size:200% 100%;
    --card-contract-color:#f1f1f1;
    --image-box-color:linear-gradient(45deg,#fff,#fff,#fff);
    --image-animation:imageFilp 1s alternate linear;
    --image-transform:translateZ(20);
    --image-popular-cursor:cell;
    --image-transition:all 0.6s;
    --image-50-transform:translateY(50px);
    --image-100-transform:translateX(0px);
    --card-name-transform:translateY(100px);
    --card-name-0-transition:all 0.5s;
    --card-name-50-letter-spacing:0.5px;
    --card-name-common-transition:all 0.6s;
    --card-name-50-transform:translateY(0px);
    --card-name-100-letter-spacing:1px;
    --card-name-100-transform:translateX(10px);
    --card-name-animation:textDesign 2s linear infinite alternate;

}
#popular{
    background-color: #f2f3f8;
}
section#popular{
    /* background: url('/public/product-category-image/category-bg.png'); */
    font-family: var(--font-family-features);
    padding-bottom: 50px;
    height: auto;
}
.container.popular-category-container{
    padding-top: 5px;
    padding-bottom: 25px;
    border-radius: 3px;
    /* background-color: #f1f1; */
    background-color: #fff;
}
a#PopularButton,#PopularBrandButton{
    outline: none;
    box-shadow: none;
    width: 90px;
    margin-left: 76px;
}
.popular__img {
    margin-top: -132px;
    height: 79px;
    width: 89px;
    margin-left: -49px;
}
span.popular__text__demo {
    height: 80px;
    display: flex;
    align-items: center;
    width: 129px;
    margin-left: 95px;
    margin-top: -496px;
    overflow: hidden;
}
img.popular__category__img {
    width: 100px;
    height: 100px;
    margin-top: -238px;
    padding-left: 5px;
    padding-right: 15px;
    padding-top: 18px;
    padding-bottom: 4px;
    transition: transform 3s;
}
.popular__category__img:hover{
    width: 100px;
    height: 90px;
    margin-top: -225px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: all 0.6s;
    opacity: 20;
    cursor: var(--image-popular-cursor);
    background-image: var(--image-box-color);
    transform: var(--image-transform);
    animation: var(--image-animation);
    background: rgb(0,0,0,0.02);
}
@keyframes imageFilp{
    50%{
        transition: var(--image-transition);
        transform: var(--image-50-transform);
    }
    100%{
        transition: var(--image-transition);
        transform: var(--image-100-transform);
    }
}
.demo-popular-bg-off{
    display: none;
}
.demo-popular-text-off, .popular__demo__bg__text__off{
    display: none;
}
svg.icon.popular-svg{
    margin-top: -128px;
    margin-left: -134px;
}
.popular-svg-display-off{
    display: none;
}
.popular__img2 {
    margin-top: 100px;
    width: 130px;
    margin-left: 47px;
}
.popular__category__box.card{
    width: 250px;
    height: 100px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: default;
    overflow: hidden;
    border: 1px solid #e2e5ec !important;
    transition: all 400ms ease;
}
.popular__category__box{
    margin-top: 20px;
}
.popular__category__box.card:hover{
    transition: all 0.3s;
    box-shadow: var(--card-shadow);
    transform: var(--card-transform);
    opacity: 1;
    border: 1px solid #e2e5ec !important;
}
#popular-view-btn-icon{
    width: 63px;
    height: 25px;
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    color: #fff;
    font-size: 14px;
    line-height: 0.5;
    border-radius: 5px;
}
.popular__btn__icon{
    position: absolute;
    top: 0;
    right: 0;
}
.btn__icon__mini{
    display: grid;
    position: relative;
    justify-content: center;
    align-items: center;
}

.btn__icon__mini a{
    visibility: hidden;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 32px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    margin-top: -85px;
    margin-left: 200px;
    margin-right: 5px;
    color: #333;
    font-size: 15px;
    box-shadow: var(--btn-icon-shadow);
    transform: var(--btn-icon-transform);
    -webkit-transform: var(--btn-icon-webkit-transform);
}
.btn__icon__mini a:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    color: #fff;
}
.view__btn__icon__mini a{
    visibility: hidden;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 32px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    margin-top: -85px;
    margin-left: 220px;
    margin-right: 5px;
    color: #333;
    font-size: 15px;
    box-shadow: var(--btn-icon-shadow);
    transform: var(--btn-icon-transform);
    -webkit-transform: var(--btn-icon-webkit-transform);
}
.view__btn__icon__mini a:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    color: #fff;
}
.popular__category__box.card:hover .btn__icon__mini a{
    visibility: visible;
    transition: all 0.6s;
    transform: translateX(-3%);
}

.popular-doted-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);
}
.popular-doted-skeletone::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: 1;
    z-index: 2;
    margin-top: -133px;
    margin-left: -133px;
    min-height: 80px;
    width: 90px;
    border-radius: 1px;
    background: var(--dot-skeletone-bg);
    background-size: var(--animation-background-size);
    animation-name: var(--animation);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
@keyframes css-skeleton-loading {
    0% {
        background-position: var(--animation-background-position-0);
    }
    
    100% {
        background-position: var( --animation-background-position-100);
    }
}
.star__popular__group {
    display: flex;
    margin-left: 100px;
    margin-top: -500px;
}
.popular-title-display-block {
    display: block;
    font-size: 12px;
    margin-left: 100px;
    margin-top: -8px;
    font-family: var(--font-family-features);
    animation: var(--card-name-animation);
    transform: var(--card-name-transform);
}
.popular-category-title-skeletone, .popular-rating-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);
}
.popular-category-title-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: 1;
    z-index: 2;
    margin-top: 55px;
    margin-left: 116px;
    min-height: 10px;
    width: 100px;
    border-radius: 5px;
    background: var(--dot-skeletone-bg);
    background-size: 400% 100%;
    animation-name: css-skeleton-loading;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
.popular-rating-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: 1;
    z-index: 2;
    margin-top: 30px;
    margin-left: 16px;
    min-height: 10px;
    width: 100px;
    border-radius: 5px;
    background: var(--dot-skeletone-bg);
    background-size: 400% 100%;
    animation-name: css-skeleton-loading;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
.popular-title-display-off{
    display: none;
}

.popular-category-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);  
}
.popular-category-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: -12px;
    z-index: 2;
    margin-top: 20px;
    min-height: 10px;
    width: 250px;
    border-radius: 5px;
    background: var(--dot-skeletone-bg);
    background-size: 400% 100%;
    animation-name: css-skeleton-loading;
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
.popular-category-name::after{
    content: 'Popular Category';
    font-family: var(--font-family-features);
    font-size: 15px;
    display: inline;
    font-weight: 900;
    color: darkgray;
    text-transform: uppercase;
    word-spacing: 13px;
    letter-spacing: 1px;
    --tw-bg-opacity: 1;
    border-bottom: 2px solid rgb(99 102 241 / var(--tw-bg-opacity));
    margin-left: -12px;
    padding-bottom: 2px;
    animation: head_lighting 6s alternate infinite ease-in-out;
}
.popular-brand-name::after{
    content: 'Popular Brand';
    font-family: var(--font-family-features);
    font-size: 15px;
    display: inline;
    font-weight: 900;
    color: darkgray;
    text-transform: uppercase;
    word-spacing: 13px;
    letter-spacing: 1px;
    --tw-bg-opacity: 1;
    border-bottom: 2px solid rgb(99 102 241 / var(--tw-bg-opacity));
    margin-left: -12px;
    padding-bottom: 2px;
    animation: head_lighting 6s alternate infinite ease-in-out;
}



