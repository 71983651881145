:root{
    --font-family-blog:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    --text-keyword:0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 
    0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent, 
    0 10px 10px rgb(0, 0,0,0.4);
    --component-bg:linear-gradient(45deg,rgba(30, 30, 30, 0.85),rgba(30, 30, 30, 0.85));
    --main-slider-bg:linear-gradient(90deg,#e6e6e6 25%,#d4d4d4 37%,#f1f1f1 63%);
    --main-slider-image-size:400% 100%;
    --background-position-0:100% 50%;
    --background-position-100:0 50%;
    --skeletone-animation:1.4s infinite skeletone;
    --animation-name:css-skeleton-loading;
    --dot-skeletone-bg:linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
}
section#blog{
    height: 130vh;
}
.blog__image{
    width: 1200px;
    height: 425px;
    /* background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)); */
}    
.blog-container{
    margin-bottom: 150px;
    margin-top: 40px;
}
/* .blog-container:hover{
    background: rgba(0, 0, 0, 0.06);
    transition: all 0.6s;
} */
.blog-card-body{
    margin-bottom: 50px;  
}
.blog-svg-image{
    position: relative;
}
svg.blog__image{
    margin-top: -105px;
    margin-left: 355px;
    width: 400px;
    height: 220px;

}
.blog-page-skeletone{
    position:relative;
    background-color: rgb(0,0,0,0.06);
    vertical-align: top;
}
.blog-page-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: -3px;
    top: -3px;
    width: 1120px;
    height: 428px;
    /* z-index: 9; */
    background: var(--main-slider-bg);
    background-size: var(--main-slider-image-size);
    animation-timing-function: ease;
    animation: var(--skeletone-animation);
}
@keyframes skeletone{
    0%{
      background-position: var(--background-position-0);
    }
    100%{
      background-position: var(--background-position-100);
    }
}
.blog-content-title-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);
}
.demo-blog-bg {
    width: 100%;
    height: 470px;
    position: relative;
    margin-top: -430px;
}
.demo-blog-bg-off{
    display: none;
}
.blog-content-title-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: 355px;
    /* z-index: 2; */
    margin-top: -75px;
    min-height: 15px;
    width: 398px;
    border-radius: 5px;
    background: var(--dot-skeletone-bg);
    background-size: 400% 100%;
    animation-name: var(--animation-name);
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
@keyframes css-skeleton-loading{
    0%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}
.blog-head-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);
}
.blog-head-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: -50px;
    z-index: 2;
    margin-top: 200px;
    min-height: 45px;
    width: 100px;
    border-radius: 5px;
    background: var(--dot-skeletone-bg);
    background-size: 400% 100%;
    animation-name: var(--animation-name);
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
.faq-head-skeletone{
    position: relative;
    background-color: rgb(0,0,0,0.06);
}
.faq-head-skeletone::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    justify-content: space-between;
    left: -50px;
    z-index: 2;
    margin-top: 200px;
    min-height: 45px;
    width: 100px;
    border-radius: 5px;
    background: var(--dot-skeletone-bg);
    background-size: 400% 100%;
    animation-name: var(--animation-name);
    animation-duration: 1.4s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}
img.logo_display{
    display: block;
    width: 90px;
    margin-top: -320px;
    margin-left: 250px;
    height: 60px;
    border-radius: 50%;

}
.blog__display__title::after{
    content: 'GST MEDICINE Blog Site....';
}
.faq__title__display::after{
    content: 'FAQ';
    margin-top: 100px;
}
.blog__head__display::after{
    content: 'BLOG';
    margin-top: 120px;
}
.blog-svg-mode-off{
    display: none;
}
.blog__heading__paragraph{
    font-family: var(--font-family-blog);
    font-weight: 900;
    font-size: 28px;
    letter-spacing: 1px;
    position: relative;
    margin-top: -300px;
    margin-left: 370px;
}
.blog__button_group{
    display: flex;
    position: relative;
    margin-top: -280px;
    margin-left: 300px;
    
}
#blog__btnVideo{
    outline: none;
    box-shadow: none;
}
#blog__btnPost{
    outline: none;
    box-shadow: none;
}